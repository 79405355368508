.aboutme_text {
	color: var(--text_color);
	line-height: 22px !important;
	text-align: justify;
	text-justify: inter-word;
}
/* Skills */
.skill {
	border-radius: 8px;
	padding: 20px;
	box-shadow: 0px 4px 5px -2px rgb(0 0 0 / 20%), 0px 7px 10px 1px rgb(0 0 0 / 14%), 0px 2px 16px 1px rgb(0 0 0 / 12%) !important;
	min-height: 186px;
	height: 100%;
}
.skill .skill_title {
	font-size: 16px !important;
	font-weight: bold !important;
	line-height: 35px !important;
}
.skill .skill_description {
	display: flex;
	align-items: center;
	color: var(--text_color) !important;
	font-weight: 400 !important;
	line-height: 18px !important;
}
.skill .skill_description .skill_dot {
	border-color: var(--primary) !important;
	padding: 2px !important;
	margin-right: 5px !important;
}

