.custom_btn {
	background-color: var(--primary) !important;
	font-size: 14px !important;
	font-weight: 500 !important;
	border: 0 !important;
	border-radius: 50px !important;
	padding: 1px 12px !important;
	line-height: 35px !important;
	min-width: 100px !important;
	outline: none !important;
}
.custom_btn .btn_icon_container {
	color: var(--black) !important;
	background-color: var(--white) !important;
	border-radius: 50px !important;
	height: 27px !important;
	width: 27px !important;
	line-height: 29px !important;
	text-align: center !important;
	display: flex !important;
	align-items: center !important;
	justify-content: center !important;
}
.custom_btn .btn_icon_container > .MuiSvgIcon-root {
	font-size: 16px !important;
}
.custom_btn .btn_text {
	font-size: 14px !important;
	text-transform: none !important;
	text-align: center !important;
	width: 100%;
	margin-right: 5px;
	margin-left: 5px;
}
.custom_btn:hover {
	color: var(--white) !important;
	background-color: var(--secondary) !important;
}
.custom_btn:hover .btn_icon_container {
	color: var(--white) !important;
	background-color: var(--black) !important;
}
