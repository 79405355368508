/* Header title */
.timeline .timeline_header {
	padding-top: 7px;
}
.timeline .timeline_firstItem {
	min-height: 80px !important;
}
.timeline .MuiTimelineItem-root {
	min-height: 0px;
}
.timeline .MuiTimelineItem-missingOppositeContent::before {
	display: none;
}
.timeline .timeline_dot_header {
	color: black;
	background-color: var(--primary);
	font-size: small;
	padding: 10px;
	border-color: var(--primary);
}
/* Remaining Items */
.timeline .timeline_dot {
	color: var(--black);
	border-color: var(--primary);
	padding: 2px;
}
.timeline .MuitimelineConnector-root {
	background-color: #eee !important;
}
.timeline .timeline_dot_header > .MuiSevIcon-root {
	font-size: 20px;
	color: var(--text_color) !important;
}
.timeline .separator_padding {
	padding-left: 20px;
}
.timeline_item_link {
	color: var(--black) !important;
	text-decoration: none;
}
.timeline_item_link:hover {
	color: var(--secondary) !important;
}
.timelineItem_text_address {
	color: var(--text_color) !important;
}
