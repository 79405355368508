.header .header_link {
	font-weight: 500;
	color: var(--black) !important;
	margin: 0 10px !important;
}
.header .header_link:hover {
	color: var(--secondary) !important;
}
.header .header_link-active {
	margin: 0 10px !important;
	font-weight: 500;
	color: var(--secondary) !important;
}

.header .header_link-active .MuiFab-root {
	background-color: var(--black) !important;
}
.nav_link {
	padding: 7px !important;
	height: 40px !important;
	width: 40px !important;
}
