.certificate .customCard {
	height: auto;
	width: 270px;
}
.certificate .customCard_title {
	font-size: 14px !important;
}
.certificate .customCard_subtitle {
	color: var(--text_color) !important;
	font-size: 13px !important;
}
.certificate .customCard_date {
	color: var(--text_color) !important;
	font-size: 12px !important;
	margin-bottom: 10px !important;
}
.customCard_btn_link {
	width: 100%;
	display: flex !important;
	justify-content: flex-end !important;
}
.certificate .MuiGrid-container {
	justify-content: space-around !important;
}
