:root {
	--primary: #66fcf1;
	--secondary: #fe980f;
	--white: #fff;
	--black: #000;
	--bg_white: #fafafa;
	--text_color: #777;
}
* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}
html {
	scroll-behavior: smooth;
}
body {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background: var(--bg_white);
	overflow-x: hidden;
	/* background: url("./assets/images/bg.jpg"); */
}
a {
	text-decoration: none !important;
}
ul li {
	list-style: none;
}
.container_shadow {
	box-shadow: 0px 4px 5px -2px rgb(0 0 0 / 20%), 0px 7px 10px 1px rgb(0 0 0 / 14%), 0px 2px 16px 1px rgb(0 0 0 / 12%);
	-webkit-box-shadow: 0px 4px 5px -2px rgb(0 0 0 / 20%), 0px 7px 10px 1px rgb(0 0 0 / 14%),
		0px 2px 16px 1px rgb(0 0 0 / 12%);
	-moz-box-shadow: 0px 4px 5px -2px rgb(0 0 0 / 20%), 0px 7px 10px 1px rgb(0 0 0 / 14%),
		0px 2px 16px 1px rgb(0 0 0 / 12%);
}
.app .MuiGrid-spacing-xs-5 {
	margin-top: 0 !important;
}
.main_content {
	background-color: var(--white) !important;
	border-radius: 6px;
	-moz-border-radius: 6px;
	-webkit-border-radius: 6px;
	margin-bottom: 30px;
	padding: 16px;
}
.section {
	padding-bottom: 30px !important;
}
.section_title {
	position: relative;
	display: inline-block;
	padding: 0 3px;
	margin-bottom: 10px !important;
}
.section_title .section_title_text {
	position: relative;
	font-size: 20px;
	font-weight: 600;
	margin: 0;
	z-index: 1;
}
.section_title span {
	position: absolute;
	height: 8px;
	width: 100%;
	bottom: 5px;
	opacity: 0.5;
	left: 0;
	background-color: var(--secondary);
}
.MuiTimelineContent-root.MuiTimelineItem-content.timeline_content {
	margin-top: -10px !important;
}
.timelineItem_text {
	font-size: 14px !important;
	color: var(--text_color);
	font-weight: 400 !important;
	margin-top: 5px !important;
}
.timeline_content > span {
	color: var(--black);
	font-size: 17px;
	font-weight: bold;
}
.link_btn {
	color: var(--black) !important;
}
.link_btn:hover {
	color: var(--white) !important;
}
.app {
	margin-top: 45px !important;
}
@media screen and (max-width: 956px) {
	.app {
		margin-top: 35px !important;
	}
}
@media (min-width: 600px) {
	.MuiTab-root {
		min-width: 0 !important;
	}
}
