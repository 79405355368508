.blog_link > span {
  color: var(--black);
  font-size: 17px;
  font-weight: bold;
}
.blog_link > a {
  text-decoration: none;
  color: var(--secondary);
}
.blog_link > a:hover {
  color: var(--primary);
  text-decoration: underline !important;
}
.aboutme_text {
  font-size: 1rem !important;
}
