/* Contact Section */
.contact_info_item {
	font-size: 14px !important;
	color: var(--text_color);
}
.contact_info_item > span {
	color: var(--black);
	font-weight: bold;
}
.contact_info_item > a {
	color: var(--text_color) !important;
}
.contact_info_item > a:hover {
	color: var(--secondary) !important;
}
.contact_form .MuiFormLabel-root.Mui-focused {
	color: var(--secondary) !important;
}
.contact_form .MuiInput-underline:hover:not(.Mui-disabled):before {
	border-bottom: 2px solid var(--secondary) !important;
}
.contact_form .MuiInput-underline:after {
	border-bottom: 2px solid var(--secondary) !important;
}
