.profile {
	width: 100%;
	background-color: var(--white);
	border-radius: 6px;
}
.profile_name {
	line-height: 18px;
	padding: 16px;
	position: relative !important;
}
.profile_name .name {
	text-transform: uppercase;
	font-size: 17px;
	font-weight: bold;
}
.profile_name .title {
	font-size: 13px;
	color: var(--text_color);
}
.profile_image {
	margin-top: -10px;
	-webkit-clip-path: polygon(0 9%, 100% 0, 100% 94%, 0% 100%);
	clip-path: polygon(0 9%, 100% 0, 100% 94%, 0% 100%);
}
.profile_image img {
	width: 100%;
}
/* Profile Information */
.profile_information {
	top: 0;
	left: 0;
	margin: -55px 0 0;
	padding-bottom: 16px;
}
.profile_information > ul.MuiTimeline-root.MuiTimeline-alignLeft.timeline {
	margin-bottom: 0 !important;
}
.profile_information .timeline .timeline_content {
	margin-top: -4px;
	margin-bottom: 5px;
}
.resume_after > span::after {
	display: block;
	content: "";
	width: 60px;
	height: 3px;
	position: absolute;
	background-color: var(--secondary) !important;
}
.social_media {
	font-size: 20px !important;
	padding-left: 20px !important;
}
.social_link {
	display: flex;
	padding-left: 0 !important;
	margin-left: 15px;
}
.social_link-item {
	padding: 6px;
	padding-left: 0 !important;
}
.social_link-item a {
	color: var(--black);
}
.social_link-item a:hover {
	color: var(--secondary);
}
.btn_container {
	display: flex;
	justify-content: left;
	margin: 5px 0 5px 16px;
	align-items: center;
}
.expand_icon:hover {
	color: var(--secondary) !important;
}
