.footer {
	background-color: var(--black);
	color: var(--text_color);
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
	min-height: 70px;
	border-radius: 8px;
	padding: 10px 15px;
}
.footer .footer_name {
	color: var(--white);
}
.footer .footer_copyright {
	font-size: small;
	text-align: end;
	color: var(--text_color);
}
.footer .footer_copyright > a {
	text-decoration: none;
	color: var(--secondary);
}
.footer .footer_copyright > a:hover {
	color: var(--primary);
}
