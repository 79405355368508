.customCard {
	height: auto;
	width: 270px;
}
.customTabs_item.active {
	color: var(--secondary) !important;
}
.custom_tabs .customTabs_item {
	text-transform: inherit !important;
	font-size: 14px !important;
	font-weight: bold !important;
}
.customCard .MuiCardMedia-root.customCard_image {
	background-size: contain !important;
	object-fit: cover !important;
	width: 100%;
	height: 205px;
}
.customCard_title {
	margin-bottom: 10px !important;
	font-weight: bold !important;
	font-size: 14px !important;
}
.portfolio .MuiGrid-container {
	justify-content: space-around !important;
}
